import React from "react"
import './style.css'

import {
    IoBackspaceOutline, 
} from "react-icons/io5"

const onClick = (props) => (e) => {
    console.log(e.target.value)
    props.calcula(e.target.value)
}

function Botoes(props){

    return(
        <div className = 'botoes'>
            <button value = {'backspace'} onClick = {(e) => props.calcula('backspace')} ><IoBackspaceOutline/></button>
            <button value = {'C'} onClick = {onClick(props)}> C </button>
            <button value = {'%'} onClick = {onClick(props)}> % </button>
            <button value = {'/'} onClick = {onClick(props)} className = 'operador'>/</button>
            
            <button value = {'1'} onClick = {onClick(props)}> 1 </button>
            <button value = {'2'} onClick = {onClick(props)}> 2 </button>
            <button value = {'3'} onClick = {onClick(props)} >3 </button>
            <button value = {'*'} onClick = {onClick(props)} className = 'operador' >*</button>

            <button value = {'4'} onClick = {onClick(props)} >4</button>
            <button value = {'5'} onClick = {onClick(props)}>5</button>
            <button value = {'6'} onClick = {onClick(props)}>6</button>
            <button value = {'-'} onClick = {onClick(props)} className = 'operador'>-</button>

            <button value = {'7'} onClick = {onClick(props)}>7</button>
            <button value = {'8'} onClick = {onClick(props)}>8</button>
            <button value = {'9'} onClick = {onClick(props)}>9</button>
            <button value = {'+'} onClick = {onClick(props)} className = 'operador'>+</button>

            <button value = {'.'} onClick = {onClick(props)} >.</button>
            <button value = {'0'} onClick = {onClick(props)} >0</button>
            <button value = {'='} onClick = {onClick(props)} className = 'operador'>=</button>
        </div>
    )
}

export default Botoes